import { makeStyles } from "@mui/styles";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Box, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCodeInfo } from "../../redux/codeRef.slicer";
import ReactCodeMirror from "@uiw/react-codemirror";

const InputCompo = ({ fileName = "js" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { input } = useSelector((state) => state.tool);
  const fileInputRef = useRef(null);

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    fetch(URL.createObjectURL(file))
      .then((response) => response.text())
      .then((data) => dispatch(updateCodeInfo({ name: "input", value: data })));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleClearCode = () => {
    dispatch(updateCodeInfo({ name: "input", value: "" }));
  };
  const handleChange = (value) => {
    dispatch(updateCodeInfo({ name: "input", value: value }));
  };
  return (
    <div className={classes.inputMain}>
      <div className={classes.inputHeader}>
        <Typography className={classes.textColor}>Input {fileName}</Typography>

        <Box ml="auto" gap="10px" display="flex">
          <div className={classes.root} onClick={handleClick}>
            <Tooltip title="Upload file">
              <FileOpenIcon className={classes.textColor} />
            </Tooltip>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInput}
              accept={`.${fileName}`}
            />
          </div>
          <Tooltip title="Clear Text">
            <CloseIcon
              className={classes.textColor}
              onClick={handleClearCode}
            />
          </Tooltip>
        </Box>
      </div>
      <ReactCodeMirror
        height="100%"
        onChange={handleChange}
        value={input}
        style={{ padding: "10px" }}
        syntaxHighlighting
        autocompletion
        className={classes.input}
      />
    </div>
  );
};

export default InputCompo;
export const useStyles = makeStyles((theme) => ({
  inputMain: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.main,
    maxHeight: "90vh",
    padding: "5px",
    maxWidth: "36vw",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75vw",
    },
  },
  inputHeader: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "30px",
    borderRadius: "4px",
    padding: "0px 10px",
    boxSizing: "border-box",
    display: "flex",

    alignItems: "center",
  },

  input: {
    backgroundColor: "transparent",
    border: "none",
    height: "100%",
    minWidth: "96%",

    "&:focus": {
      outline: "none",
    },
  },

  textColor: {
    color: `#59656F `,
    fontFamily: "play fair !important",
    cursor: "pointer",
  },

  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& input": {
      display: "none",
    },
  },

  progress: {
    width: "60px",
    height: "60px",
    color: "red",
  },

  coder: {
    height: "100%",
    maxWidth: "100%",
    display: "none",
  },
}));
