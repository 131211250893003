import { Box, Typography } from "@mui/material";
import CodeRefactoro from "../../components/codeRefractor";
import { useDispatch, useSelector } from "react-redux";
import MiddleSec from "../../components/middleSec";
import Button from "../../components/button";
import xmlFormat from "xml-formatter";
import { validateXmlCode } from "../../helpers";
import { updateCodeInfo } from "../../redux/codeRef.slicer";
import CompanySection from "../../components/companySection";
import image from "../../assets/Rectangle 13.png";
import { xml2js } from 'xml-js';
import jsyaml from "js-yaml";

const XmlConverter = () => {
  const { input } = useSelector((state) => state.tool);
  const dispatch = useDispatch();
  const minifyXml = () => {
    try {
      const validationData = validateXmlCode(input);
      if (validationData) {
        dispatch(
          updateCodeInfo({ name: "output", value: "validationData.message" })
        );
      } else {
        try{
          const data = xmlFormat.minify(input, {
            indentation: "   ",
            filter: (node) => node.type !== "Comment",
            collapseContent: false,
            lineSeparator: "\n",
          });
          
          dispatch(updateCodeInfo({ name: "output", value: data }));

        } catch(e){
          const err = JSON.stringify(e.message);
          dispatch(updateCodeInfo({ name: "output", value: err }));
          dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid XML" }));
        }
      }
    } catch (error) {
      dispatch(updateCodeInfo({ name: "output", value: error }));
    }
  };
  const formatXml = () => {
    try {
      const validationData = validateXmlCode(input);
      if (validationData) {
        dispatch(
          updateCodeInfo({ name: "output", value: validationData.message })
        );
      } else {
        try{
          const data = xmlFormat(input, {
            indentation: "   ",
            filter: (node) => node.type !== "Comment",
            collapseContent: false,
            lineSeparator: "\n",
            });
          dispatch(updateCodeInfo({ name: "output", value: data  }));
          dispatch(updateCodeInfo({ name: "tempMessage", value: "Valid XML" }));
        } catch (e){
          const err = JSON.stringify(e.message);
          dispatch(updateCodeInfo({ name: "output", value: err }));
          dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid XML" }));
        }
        }
    } catch (error) {
      dispatch(updateCodeInfo({ name: "output", value: error }));
    }
  };
  const validateXml = () => {
    const validationData = validateXmlCode(input);
    if (validationData) {
      dispatch(updateCodeInfo({ name: "output", value: validationData.message }));
      dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid XML" }));
    } else {
      try {
        const data = xmlFormat(input, {
          indentation: "   ",
          filter: (node) => node.type !== "Comment",
          collapseContent: false,
          lineSeparator: "\n",
        });
        dispatch(updateCodeInfo({ name: "output", value: data  }));
        dispatch(updateCodeInfo({ name: "tempMessage", value: "Valid XML" }));
      } catch (e) {
        const err = JSON.stringify(e.message);
        dispatch(updateCodeInfo({ name: "output", value: err }));
        dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid XML" }));
      }
      
    }
  };
  const handleXmlToJson = () => {
    try {
      const xmlObject = xml2js(input, { compact: true, spaces: 4 });
      const jsonString = JSON.stringify(xmlObject, null, 2);
  
      dispatch(updateCodeInfo({ name: "output", value: jsonString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  const handleXmlToYaml = () => {
    try {
      const xmlObject = xml2js(input, { compact: true, spaces: 4 });
      const yamlString = jsyaml.dump(xmlObject, { indent: 2 });
       
      dispatch(updateCodeInfo({ name: "output", value: yamlString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {" "}
        <Typography
          sx={{
            fontFamily: " PT Sans",
            fontSize: "32px",
            fontWeight: "700",
            padding: "20px ! important",
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          XML Viewer
        </Typography>
      </Box>

      <CodeRefactoro language={"xml"} fileType={"xml"}>
        {" "}
        <MiddleSec>
          <Button variant="outlined" onClick={validateXml}>
            Validate
          </Button>
          <Button variant="outlined" onClick={formatXml}>
            Format
          </Button>

          <Button variant="outlined" onClick={minifyXml}>
            minify
          </Button>
          <Button variant="outlined" onClick={handleXmlToJson}>
            XML to JSON
          </Button>
          <Button variant="outlined" onClick={handleXmlToYaml}>
            XML to YAML
          </Button>
        </MiddleSec>
      </CodeRefactoro>

      {/* <CompanySection
        companyName="Your Company Name"
        description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
            feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
            mattis et lectus. Volutpat eu suspendisse commodo ipsum habitant eu
            et porta urna. Feugiat augue tortor massa libero duis eu.
            Velit tempus integer urna lacus donec ac faucibus faucibus habitant.
            Velit duis eu mauris volutpat amet hac etiam. Arcu posuere lectus
            nunc mi commodo aliquet at laoreet. Massa mi adipiscing non
            fermentum. Tellus curabitur commodo penatibus risus nunc accumsan.
            Ac accumsan lacinia lorem porttitor euismod sed felis morbi blandit.
            Commodo morbi nibh massa neque egestas nibh. Faucibus feugiat
            molestie sit quis proin nisi id ac ultrices. Viverra cras ipsum et
            aliquam neque aliquet eget fusce elementum. Adipiscing nisl commodo
            elementum sed ultrices vel nec nibh ultricies. Quam euismod augue id
            fames a gravida nunc. Morbi nunc ornare ornare morbi. Consectetur
            arcu morbi blandit leo at. Massa nunc ullamcorper morbi imperdiet
            habitant egestas. Dui sit congue praesent dignissim feugiat vel sed
            odio. Lorem ipsum dolor sit amet consectetur. Malesuada nunc
            maecenas feugiat volutpat amet gravida nisi libero. Lorem pharetra
            vestibulum mattis et lectus. Volutpat eu suspendisse commodo ipsum
            habitant eu et porta urna. Feugiat augue tortor massa libero duis
            eu. Velit tempus integer urna lacus donec ac faucibus faucibus
            habitant. Velit duis eu mauris volutpat amet hac etiam. Arcu posuere
            lectus nunc mi commodo"
        xmlName="Format XML File"
        xmlInstruction="Format XML file Here Are the steps"
        xmlDescription="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
        mattis et lectus.Lorem ipsum dolor sit amet consectetur. Malesuada
        nunc maecenas feugiat volutpat amet gravida nisi libero. Lorem
        pharetra vestibulum mattis et lectus."
        step1Description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra
        vestibulum mattis et lectus.Lorem ipsum dolor sit amet
        consectetur."
        step2Description="  Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra
        vestibulum mattis et lectus.Lorem ipsum dolor sit amet
        consectetur."
        image={image}
      /> */}
    </Box>
  );
};

export default XmlConverter;
