import { makeStyles } from "@mui/styles";

import { useState } from "react";

import InputCompo from "../inputCompo";
import Refactor from "../outputCompo";

const CodeRefactoro = ({ children, fileType }) => {
  const classes = useStyles();
  const [code, setCode] = useState("");
  return (
    <div className={classes.refactor}>
      <InputCompo setCode={setCode} code={code} fileName={fileType} />

      {children}
      <Refactor code={code} fileName={fileType} />
    </div>
  );
};

export default CodeRefactoro;

const useStyles = makeStyles((theme) => ({
  refactor: {
    display: "grid",
    gridTemplateColumns: "1fr .5fr 1fr",
    minHeight: "80vh",
    margin: "auto",
    width: "calc(100% - 80px)",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",

      height: "100%",
    },
  },
}));
