import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
/**
 * @dev custom button designed upon Material UI's Button component. It accepts all props the MuI button accepts. moreover, the following props have been overridden. the default styles is contained primary
 * @props variant can be either contained or outlined
 * @props color can be primary or secondary
 */
export default withStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%  ",
    "& .MuiButton-root": {},
  },

  outlined: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[800] + " !important",
    border: " none",
    borderRadius: "3px !important",
    padding: "7px 10px !important",
    fontSize: "20px  ",
    fontWeight: "700px  ",

    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[800], 0.7),
      cursor: "pointer",
    },
  },
}))(Button);
