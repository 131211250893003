import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  input: "",
  output: null,
  error: null,
  tempMessage: null,
};

const codeRef = createSlice({
  name: "code-ref",

  initialState,
  reducers: {
    updateCodeInfo: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export default codeRef.reducer;

export const { updateCodeInfo } = codeRef.actions;
