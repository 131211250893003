import { Box, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import CompanySection from "../companySection";
import image from "../../assets/Rectangle 13.png";
const NumberToTextConverter = () => {
  const classes = useStyles();
  const [inputNumber, setInputNumber] = useState("");
  const [outputText, setOutputText] = useState("");

  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const convertNumberToText = (number) => {
    if (isNaN(number) || number < 0 || number > 99999999999999999) {
      return "Invalid input";
    }

    if (number === 0) {
      return "zero";
    }

    let text = "";
    if (number >= 10000000000000000) {
      text +=
        convertNumberToText(Math.floor(number / 1000000000000000)) +
        " nonillion ";
      number %= 10000000000000000;
    }
    if (number >= 1000000000000000) {
      text +=
        convertNumberToText(Math.floor(number / 1000000000000000)) +
        " quadrillion ";
      number %= 1000000000000000;
    }

    if (number >= 1000000000000) {
      text +=
        convertNumberToText(Math.floor(number / 1000000000000)) + " trillion ";
      number %= 1000000000000;
    }

    if (number >= 1000000000) {
      text +=
        convertNumberToText(Math.floor(number / 1000000000)) + " billion ";
      number %= 1000000000;
    }

    if (number >= 1000000) {
      text += convertNumberToText(Math.floor(number / 1000000)) + " million ";
      number %= 1000000;
    }

    if (number >= 1000) {
      text += convertNumberToText(Math.floor(number / 1000)) + " thousand ";
      number %= 1000;
    }

    if (number >= 100) {
      text += ones[Math.floor(number / 100)] + " hundred ";
      number %= 100;
    }

    if (number >= 20) {
      text += tens[Math.floor(number / 10)] + " ";
      number %= 10;
    } else if (number >= 10) {
      text += teens[number - 10];
      number = 0;
    }

    if (number > 0) {
      text += ones[number];
    }

    return text.trim();
  };

  const handleInputChange = (event) => {
    setInputNumber(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const number = parseInt(inputNumber);
    const text = convertNumberToText(number);
    setOutputText(text);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px",
        }}
      >
        <Typography
          sx={{
            fontFamily: " PT Sans",
            fontSize: "32px",
            fontWeight: "700",
            margin: "0px",
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          Number to Words
        </Typography>
      </Box>

      <div>
        <form onSubmit={handleFormSubmit} className={classes.main}>
          <div className={classes.numberSection}>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: 19,
                textAlign: "center",
                padding: 0.5,
                backgroundColor: (theme) => theme.palette.grey[400],
              }}
            >
              {" "}
              Enter a number
            </Box>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[400],
                width: "100%",
                height: "100%",
              }}
            >
              {" "}
              <textarea
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  outline: "none",
                }}
                type="number"
                value={inputNumber}
                onChange={handleInputChange}
              />
            </Box>
          </div>
          <Box
            sx={{
              padding: "30px 90px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.grey[400],
            }}
          >
            {" "}
            <button className={classes.btn} type="submit">
              Convert
            </button>
          </Box>
          <div className={classes.outPut}>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: 19,
                textAlign: "center",
                padding: 0.5,
                backgroundColor: (theme) => theme.palette.grey[400],
              }}
            >
              {" "}
              Output in Words
            </Box>{" "}
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[400],
                width: "100%",
                height: "100%",
              }}
            >
              <Typography>{outputText}</Typography>
            </Box>
          </div>
        </form>
      </div>
      {/* <CompanySection
        companyName="Your Company Name"
        description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
        mattis et lectus. Volutpat eu suspendisse commodo ipsum habitant eu
        et porta urna. Feugiat augue tortor massa libero duis eu.
        Velit tempus integer urna lacus donec ac faucibus faucibus habitant.
        Velit duis eu mauris volutpat amet hac etiam. Arcu posuere lectus
        nunc mi commodo aliquet at laoreet. Massa mi adipiscing non
        fermentum. Tellus curabitur commodo penatibus risus nunc accumsan.
        Ac accumsan lacinia lorem porttitor euismod sed felis morbi blandit.
        Commodo morbi nibh massa neque egestas nibh. Faucibus feugiat
        molestie sit quis proin nisi id ac ultrices. Viverra cras ipsum et
        aliquam neque aliquet eget fusce elementum. Adipiscing nisl commodo
        elementum sed ultrices vel nec nibh ultricies. Quam euismod augue id
        fames a gravida nunc. Morbi nunc ornare ornare morbi. Consectetur
        arcu morbi blandit leo at. Massa nunc ullamcorper morbi imperdiet
        habitant egestas. Dui sit congue praesent dignissim feugiat vel sed
        odio. Lorem ipsum dolor sit amet consectetur. Malesuada nunc
        maecenas feugiat volutpat amet gravida nisi libero. Lorem pharetra
        vestibulum mattis et lectus. Volutpat eu suspendisse commodo ipsum
        habitant eu et porta urna. Feugiat augue tortor massa libero duis
        eu. Velit tempus integer urna lacus donec ac faucibus faucibus
        habitant. Velit duis eu mauris volutpat amet hac etiam. Arcu posuere
        lectus nunc mi commodo"
        xmlName="Format XML File"
        xmlInstruction="Format XML file Here Are the steps"
        xmlDescription="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
    feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
    mattis et lectus.Lorem ipsum dolor sit amet consectetur. Malesuada
    nunc maecenas feugiat volutpat amet gravida nisi libero. Lorem
    pharetra vestibulum mattis et lectus."
        step1Description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
    feugiat volutpat amet gravida nisi libero. Lorem pharetra
    vestibulum mattis et lectus.Lorem ipsum dolor sit amet
    consectetur."
        step2Description="  Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
    feugiat volutpat amet gravida nisi libero. Lorem pharetra
    vestibulum mattis et lectus.Lorem ipsum dolor sit amet
    consectetur."
        image={image}
      /> */}
    </Box>
  );
};

export default NumberToTextConverter;
const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 15px",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      placeItems: "center",
      rowGap: 50,
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  },

  numberSection: {
    display: "flex",
    padding: 5,
    rowGap: 20,
    flexDirection: "column",
    height: 300,
    width: 400,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },

    "& .Input": {
      backgroundColor: theme.palette.grey[400],
      border: "none",
      width: "98%",
      outline: "none",

      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },

      " &::placeholder": {
        textAlign: "left",
      },
    },
  },

  outPut: {
    display: "flex",
    padding: 5,
    rowGap: 20,
    flexDirection: "column",
    height: 300,
    width: 400,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[800] + " !important",
    border: " none",
    borderRadius: "3px !important",
    padding: "7px 10px !important",
    fontSize: "20px  ",
    fontWeight: "700px  ",

    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[800], 0.7),
      cursor: "pointer",
    },
  },
}));
