import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/footer";
import Home from "../pages/home";
import ButtonAppBar from "../components/header";
import Yaml from "../pages/yaml";
import XmlConverter from "../pages/xml";
import Json from "../pages/json";
import NumberToTextConverter from "../components/numToWordCampo";

export default function Routers() {
  return (
    <Router>
      <ButtonAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/yaml" element={<Yaml />} />
        <Route path="/xml" element={<XmlConverter />} />
        <Route path="/json" element={<Json />} />
        <Route path="/number" element={<NumberToTextConverter />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}
