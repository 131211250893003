import { xml2js } from "xml-js";

export const validateXmlCode = (xmlCode) => {
  try {
    // Parse the XML code and convert it to a JavaScript object
    xml2js(xmlCode, { compact: true });

    return null;
  } catch (error) {
    // Return an array with an error object if the XML code is invalid
    const message = error.message;
    return { message };
  }
};
