import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import DividerHome from "../divider";

const CompanySection = ({
  companyName,
  description,
  xmlName,
  xmlInstruction,
  xmlDescription,
  step1Description,
  step2Description,
  image,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.dataContainer}>
        <div className={classes.companySection}>
          <Typography variant="h5" sx={{ fontWeight: "700", fontSize: 32 }}>
            {companyName}
          </Typography>
          <DividerHome />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1">{description}</Typography>
        </div>
        <Box sx={{ marginTop: "40px" }} className={classes.companySection}>
          <Typography variant="h5" sx={{ fontWeight: "700", fontSize: 32 }}>
            {xmlName}
          </Typography>
          <DividerHome />
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Typography sx={{ fontWeight: "700", fontSize: 26 }}>
            {xmlInstruction}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1">{xmlDescription}</Typography>
        </Box>
        <Box className={classes.stepsContainer} sx={{}}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "700", fontSize: 32 }}>
              step 1:
            </Typography>
            <Typography variant="body1">{step1Description}</Typography>
            <Box
              sx={{ padding: { md: "15px ", sm: "15px 0px", xs: "15px 0px" } }}
            >
              <img className={classes.image} src={image} alt="pic" />
            </Box>
          </Box>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "700", fontSize: 32 }}>
              step 2:
            </Typography>
            <Typography variant="body1">{step2Description}</Typography>
            <Box
              sx={{ padding: { md: "15px ", sm: "15px 0px", xs: "15px 0px" } }}
            >
              <img className={classes.image} src={image} alt="pic" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanySection;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "100px 0px",
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: "30px 0px 30px 0px",

    [theme.breakpoints.down("sm")]: {
      padding: "30px 14px 30px 14px",
    },
  },
  dataContainer: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  companySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer: {
    padding: "30px 0px 10px 0px",
  },
  stepsContainer: {
    display: "grid",
    gap: "50px",
    gridTemplateColumns: "1fr 1fr",
    paddingLeft: "20px",
    paddingTop: "30px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      paddingLeft: "0px",
      paddingTop: "0px",
    },
  },
  image: {
    maxWidth: "100%",
  },
}));
