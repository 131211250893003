import { useDispatch, useSelector } from "react-redux";
import CodeRefactoro from "../../components/codeRefractor";
import { updateCodeInfo } from "../../redux/codeRef.slicer";
import jsyaml from "js-yaml";
import MiddleSec from "../../components/middleSec";
import Button from "../../components/button";
import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CompanySection from "../../components/companySection";
import image from "../../assets/Rectangle 13.png";
import { js2xml } from 'xml-js';

const Yaml = () => {
  const { input, tempMessage } = useSelector((state) => state.tool);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleYamlValidator = () => {
    try {
      const yamlObject = jsyaml.load(input);
      const data = jsyaml.dump(yamlObject, {
        indent: 2,
        noCompatMode: true,
      });
      dispatch(updateCodeInfo({ name: "tempMessage", value: "Valid YAML" }));
      dispatch(updateCodeInfo({ name: "output", value: data }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid YAML" }));
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };  
  const handleYamlToJson = () => {
    try {
      const yamlObject = jsyaml.load(input);
      const jsonString = JSON.stringify(yamlObject, null, 2);
  
      dispatch(updateCodeInfo({ name: "output", value: jsonString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  const handleYamlToXml = () => {
    try {
      const yamlObject = jsyaml.load(input);
      const xmlString = js2xml(yamlObject, { compact: true, spaces: 4 });
  
      dispatch(updateCodeInfo({ name: "output", value: xmlString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  return (
    <>
      <div className={classes.mainSec}>
        <Typography variant="h4" className={classes.text}>
          YAML
        </Typography>

        <CodeRefactoro fileType="yaml" language={"yaml"}>
          <MiddleSec>
            {/* <Typography variant="h6" sx={{ color: "green" }}>
              {" "}
              {tempMessage}
            </Typography> */}

            <Button onClick={handleYamlValidator} variant="outlined">
              validator
            </Button>
            <Button onClick={handleYamlToJson} variant="outlined">
              YAML to JSON
            </Button>
            <Button onClick={handleYamlToXml} variant="outlined">
              YAML to XML
            </Button>
          </MiddleSec>
        </CodeRefactoro>
      </div>
      {/* <CompanySection
        companyName="Your Company Name"
        description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
         feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
         mattis et lectus. Volutpat eu suspendisse commodo ipsum habitant eu
         et porta urna. Feugiat augue tortor massa libero duis eu.
         Velit tempus integer urna lacus donec ac faucibus faucibus habitant.
         Velit duis eu mauris volutpat amet hac etiam. Arcu posuere lectus
         nunc mi commodo aliquet at laoreet. Massa mi adipiscing non
         fermentum. Tellus curabitur commodo penatibus risus nunc accumsan.
         Ac accumsan lacinia lorem porttitor euismod sed felis morbi blandit.
         Commodo morbi nibh massa neque egestas nibh. Faucibus feugiat
         molestie sit quis proin nisi id ac ultrices. Viverra cras ipsum et
         aliquam neque aliquet eget fusce elementum. Adipiscing nisl commodo
         elementum sed ultrices vel nec nibh ultricies. Quam euismod augue id
         fames a gravida nunc. Morbi nunc ornare ornare morbi. Consectetur
         arcu morbi blandit leo at. Massa nunc ullamcorper morbi imperdiet
         habitant egestas. Dui sit congue praesent dignissim feugiat vel sed
         odio. Lorem ipsum dolor sit amet consectetur. Malesuada nunc
         maecenas feugiat volutpat amet gravida nisi libero. Lorem pharetra
         vestibulum mattis et lectus. Volutpat eu suspendisse commodo ipsum
         habitant eu et porta urna. Feugiat augue tortor massa libero duis
         eu. Velit tempus integer urna lacus donec ac faucibus faucibus
         habitant. Velit duis eu mauris volutpat amet hac etiam. Arcu posuere
         lectus nunc mi commodo"
        xmlName="Format XML File"
        xmlInstruction="Format XML file Here Are the steps"
        xmlDescription="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
     feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
     mattis et lectus.Lorem ipsum dolor sit amet consectetur. Malesuada
     nunc maecenas feugiat volutpat amet gravida nisi libero. Lorem
     pharetra vestibulum mattis et lectus."
        step1Description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
     feugiat volutpat amet gravida nisi libero. Lorem pharetra
     vestibulum mattis et lectus.Lorem ipsum dolor sit amet
     consectetur."
        step2Description="  Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
     feugiat volutpat amet gravida nisi libero. Lorem pharetra
     vestibulum mattis et lectus.Lorem ipsum dolor sit amet
     consectetur."
        image={image}
      /> */}
    </>
  );
};

export default Yaml;

const useStyles = makeStyles((theme) => ({
  mainSec: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  text: {
    fontFamily: " PT Sans",
    fontSize: "32px !important",
    fontWeight: "700 !important",
    padding: "20px",
    color: theme.palette.secondary.main,
  },
}));
