import React from "react";
import CodeRefactoro from "../../components/codeRefractor";
import CompanySection from "../../components/companySection";
import image from "../../assets/Rectangle 13.png";
import NumberToTextConverter from "../../components/numToWordCampo";
import Yaml from "../yaml";
import Json from "../json";
export default function Home() {
  return (
    <div>
      <Json />
    </div>
  );
}
