import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#ffffff" },
    secondary: {
      main: "#59656F",
    },
    grey: {
      800: "#59656F",
      400: "#D9D9D9",
    },
  },
  typography: {
    fontFamily: ["PT Sans, sans-serif", '"Playfair Display,sans-serif"'].join(
      ","
    ),
  },
});
export default theme;
