import { makeStyles } from "@mui/styles";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useStyles } from "../inputCompo";
import { Box, Tooltip, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { updateCodeInfo } from "../../redux/codeRef.slicer";

const Refactor = ({ fileName = "js" }) => {
  const classes = useStyles();
  const { output, tempMessage } = useSelector((state) => state.tool);

  const dispatch = useDispatch();
  const handleDownload = () => {
    if (!output) {
      dispatch(
        updateCodeInfo({ name: "tempMessage", value: "No file to Download!" })
      );
      return;
    }
    const element = document.createElement("a");
    const file = new Blob([output], { type: `text/${fileName}` });
    element.href = URL.createObjectURL(file);
    element.download = `output.${fileName}`; // Change the filename as needed
    document.body.appendChild(element);
    element.click();
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(output)
      .then(() =>
        dispatch(
          updateCodeInfo({ name: "tempMessage", value: "Value copied..." })
        )
      )
      .catch((err) =>
        dispatch(updateCodeInfo({ name: "tempMessage", value: err }))
      );

    setTimeout(() => {
      dispatch(updateCodeInfo({ name: "tempMessage", value: null }));
    }, 1500);
  };
  return (
    <div className={classes.inputMain}>
      <div className={classes.inputHeader}>
        <Typography className={classes.textColor}>output {fileName}</Typography>

        <Box ml="auto" gap="10px" display="flex">
          <div className={classes.root}></div>
          <Tooltip title="download file">
            <ArrowDownwardIcon
              className={classes.textColor}
              onClick={handleDownload}
            />
          </Tooltip>
          <Tooltip title="copy">
            <ContentCopyIcon
              className={classes.textColor}
              onClick={handleCopy}
            />
          </Tooltip>
        </Box>
      </div>
      <SyntaxHighlighter
        language={fileName}
        className={classes.coder}
        style={a11yLight}
      >
        {output}
      </SyntaxHighlighter>
    </div>
  );
};

export default Refactor;
