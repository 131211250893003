import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Routers from "./routes";
import './App.css';

import ReduxProvider from "./redux/ReduxProvider";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider>
        <Routers />
      </ReduxProvider>
    </ThemeProvider>
  );
};

export default App;
