import React, { useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from "@mui/icons-material/Cancel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  List,
  ListItem,
  ListSubheader,
  Select,
  SwipeableDrawer,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function ButtonAppBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <Typography variant="h4" className={classes.title}>
              The DevOps Guy
            </Typography>
          </Link>
          <div className={classes.titleContainer}>
            <div className={classes.pageHeadings}>
              <>
              <Link to="/json" style={{paddingRight: '20px',fontWeight:"bold" }}>JSON Converter</Link>
              </>
              <>
              <Link to="/yaml" style={{paddingRight: '20px',fontWeight:"bold" }}>YAML Converter</Link>
              </>
              <>
              <Link to="/xml" style={{paddingRight: '20px',fontWeight:"bold" }}>XML Converter</Link>
              </>
              <>
                {" "}
                <Select
                  className={classes.title}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Other tools"
                  }
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    ".MuiSvgIcon-root": { color: "white" },

                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                >
                  <Box
                    sx={{
                      display: { sm: "flex" },
                      flexDirection: { sm: "row" },
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "400px",
                    }}
                  >
                    <div>
                      <Link to="/number">
                        <MenuItem className={classes.titleContainer} value={3}>
                          Number to Word
                        </MenuItem>
                      </Link>
                    </div>
                  </Box>
                </Select>{" "}
              </>
            </div>
          </div>
          {/* <div className={classes.buttonContainer}>
            <Button className={classes.buttonContained} variant="contained">
              {" "}
              SignIn
            </Button>
          </div> */}

          <IconButton
            className={classes.menuContainer}
            onClick={() => {
              setOpen(true);
            }}
          >
            <MenuIcon className={classes.menuButton} />
          </IconButton>
          <SwipeableDrawer
            className={classes.SwipeDrawer}
            anchor="right"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            onOpen={() => {}}
          >
            <Box className={classes.arrowsection} p={2}>
              <Box display="flex" alignItems="center">
                {open ? (
                  <CancelIcon
                    className={classes.iconCross}
                    variant="contained"
                    fontSize="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <List className={classes.list}>
              <ListItem>
                {/* Put items Here */}
                <Box className={classes.HeaderLinks}>
                  <Link to="/json">
                    <Box>
                      <Typography
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="h5"
                        className={classes.HeaderItems}
                      >
                        JSON Convertor
                      </Typography>
                    </Box>
                  </Link>
                  <Link to="/xml">
                    <Box>
                      <Typography
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="h5"
                        className={classes.HeaderItems}
                      >
                        XML
                      </Typography>
                    </Box>
                  </Link>
                  <Link to="/yaml">
                    <Typography
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="h5"
                      className={classes.HeaderItems}
                    >
                      YML
                    </Typography>
                  </Link>
                  <Link to="/number">
                    <Box>
                      <Typography
                        onClick={() => {
                          setOpen(false);
                        }}
                        variant="h5"
                        className={classes.HeaderItems}
                      >
                        Number to Word
                      </Typography>
                    </Box>{" "}
                  </Link>
                </Box>
              </ListItem>
            </List>
            {/* <div className={classes.drawerButtons}>
              <Button
                className={classes.buttonOutlinedDrawer}
                variant="contained"
              >
                {" "}
                SignIn
              </Button>
            </div> */}
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "grid",
    color: theme.palette.primary.main,
    gridTemplateColumns: "1fr 1fr 1fr ",
    placeContent: "space-around",
    background: theme.palette.secondary.main,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  buttonContained: { height: "35px", width: "140px" },
  textDesign: {
    textDecoration: "none",
    color: "#AFAFAF ! important",
  },
  pageHeadings: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    " & a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headings: {
    color: "#59656F",
    fontSize: "24px ! important",
    fontWeight: "600 ! important",
  },

  buttonContainer: {
    display: "flex",
    gap: "12px",

    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  menuContainer: {
    width: 70,
    height: 70,

    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  title: {
    color: ` ${theme.palette.primary.main} ! important`,
    fontSize: "16px ! important",
    fontWeight: "700 ! important",
    padding: "7px",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuButton: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
  },

  SwipeDrawer: {
    "& .MuiPaper-root": {
      width: "100%",
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
    },
  },

  arrowsection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
  },
  HeaderLinks: {
    textTransform: "uppercase",
    padding: "0px 10px",
  },
  HeaderItems: {
    padding: "10px 0px",
    color: theme.palette.primary.main,
    "& :hover": {
      cursor: "pointer",
    },
  },
  drawerButtons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
  },
  buttonOutlinedDrawer: { height: "25px", width: "100%" },

  menudropdown: {
    color: theme.palette.primary.main,
    "& .MuiMenu-paper   ": {
      position: "relative",
      borderRadius: "15px",
      top: "60px !important",
      boxShadow:
        "0 9px 8px -3px rgba(64,60,67,.24), 8px 0 8px -7px rgba(64,60,67,.24), -8px 0 8px -7px rgba(64,60,67,.24)",

      // marginLeft: 110,
      width: 400,
      height: 200,
    },
  },

  inner: {
    display: "grid",
    margin: " 10px 30px",
    columnGap: 20,
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr",

    "& .container": {
      paddingTop: 10,
    },
  },
  logo: {
    width: 200,
    height: 50,
  },

  CancelIconbtn: {
    width: 30,
    height: 30,
    position: "relative",
    top: -40,

    float: "right",
    cursor: "pointer",
  },
}));
