import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.main}>
        <Box sx={{ padding: "0px 60px 0px 0px" }}>
          <Typography sx={{ fontSize: 19, fontWeight: 400 }}>
            Lorem ipsum dolor sit amet consectetur. Sit facilisi mauris tempor
            imperdiet interdum. Quam mattis congue sit leo curabitur tristique.
            Purus morbi in odio non molestie. Cras ut pulvinar amet eget. Magna
            ullamcorper eu morbi eu faucibus facilisi. Sed neque volutpat
            volutpat ac mollis interdum morbi. Interdum magna maecenas amet
            tempus pellentesque.
          </Typography>
        </Box>
        <Box className={classes.linkSection}>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
              CSS Converters
            </Typography>

            <Box className={classes.linkdata}>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
            </Box>
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
              URL Encoders
            </Typography>

            <Box className={classes.linkdata}>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
            </Box>
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
              Data to Class
            </Typography>

            <Box className={classes.linkdata}>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
            </Box>
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
              Online Formatters
            </Typography>

            <Box className={classes.linkdata}>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
              <Typography className="links">Home</Typography>
            </Box>
          </div>
        </Box>

        <Box>
          <Box className={classes.footerdata1}>
            <Box className="iconContainer">
              <FacebookOutlinedIcon className="icon" />
              <InstagramIcon className="icon" />
              <LinkedInIcon className="icon" />
              <TwitterIcon className="icon" />
            </Box>

            <Box>
              {" "}
              <Divider
                sx={{
                  background: (theme) => theme.palette.primary.main,
                }}
              />
            </Box>
            <div className={classes.endSection}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  paddingRight: 8,
                }}
              >
                © 2023 Company name.
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20px 0px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[800],
  },
  main: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    rowGap: 30,
  },

  linkSection: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    [theme.breakpoints.down("md")]: {
      rowGap: 50,
    },
  },

  linkdata: {
    display: "flex",
    flexDirection: "column",
    rowGap: 10,

    "& .links": { fontSize: 16, fontWeight: 500 },
  },
  footerdata1: {
    display: "flex",
    rowGap: 30,
    flexDirection: "column",

    "& .iconContainer": {
      paddingTop: 10,
      display: "flex",
      columnGap: 20,

      " & .icon": {
        borderRadius: 50,
        padding: 5,
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[400],
        "& :hover": { cursor: "pointer" },
      },
    },
  },
}));
