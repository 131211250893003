import { useDispatch, useSelector } from "react-redux";
import CodeRefactoro from "../../components/codeRefractor";
import { updateCodeInfo } from "../../redux/codeRef.slicer";
import MiddleSec from "../../components/middleSec";
import Button from "../../components/button";
import image from "../../assets/Rectangle 13.png";
import { makeStyles } from "@mui/styles";
import CompanySection from "../../components/companySection";
import { Typography } from "@mui/material";
import { js2xml, json2xml } from "xml-js";
import jsyaml from "js-yaml";

const Json = () => {
  const { input } = useSelector((state) => state.tool);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleJsonToXml = () => {
    try {
      const jsonObj = JSON.parse(input); // parse JSON string to JavaScript object
      //const xmlString = js2xml({root: jsonObj}, { compact: true, spaces: 4 }); // convert JavaScript object to XML string
      const xmlObject = { root: jsonObj };
      const xmlString = `<?xml version="1.0" encoding="UTF-8" ?>\n${js2xml(xmlObject, { compact: true, spaces: 4 })}`;

      dispatch(updateCodeInfo({ name: "output", value: xmlString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  const handleJsonToYml = () => {
    try {
      // const yamlObject = jsyaml.load(input);
      const yamlString = jsyaml.dump(JSON.parse(input));
      // const data = JSON.stringify(yamlObject, null, 2);
      // dispatch(updateCodeInfo({ name: "output", value: data }));
      dispatch(updateCodeInfo({ name: "output", value: yamlString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };

  const handleJsonValidator = () => {
    try {
      const jsonData = JSON.parse(input);
      const beautifiedJsonString = JSON.stringify(jsonData, null, 2);
      dispatch(updateCodeInfo({ name: "tempMessage", value: "Valid JSON" }));
      dispatch(updateCodeInfo({ name: "output", value: beautifiedJsonString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "tempMessage", value: "Invalid JSON" }));
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };

  const handleJsonFormatter = () => {
    try {
      const jsonData = JSON.parse(input);
      const beautifiedJsonString = JSON.stringify(jsonData, null, 2);
      dispatch(updateCodeInfo({ name: "output", value: beautifiedJsonString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };
  const handleJsonMinified = () => {
    try {
      const jsonData = JSON.parse(input);
      const minifiedJsonString = JSON.stringify(jsonData, null, 0);
      dispatch(updateCodeInfo({ name: "output", value: minifiedJsonString }));
    } catch (e) {
      const err = JSON.stringify(e.message);
      dispatch(updateCodeInfo({ name: "output", value: err }));
    }
  };

  return (
    <>
      <div className={classes.mainSec}>
        <Typography variant="h4" className={classes.text}>
          JSON
        </Typography>

        <CodeRefactoro fileType="json">
          <MiddleSec>
            <Button variant="outlined" onClick={handleJsonValidator}>
              validator
            </Button>
            <Button variant="outlined" onClick={handleJsonFormatter}>
              Formatter
            </Button>
            <Button variant="outlined" onClick={handleJsonMinified}>
              Json Minified
            </Button>

            <Button variant="outlined" onClick={handleJsonToXml}>
              Json to XML
            </Button>
            <Button variant="outlined" onClick={handleJsonToYml}>
              Json to YAML
            </Button>
          </MiddleSec>
        </CodeRefactoro>
      </div>

      {/* <CompanySection
        companyName="Your Company Name"
        description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
            feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
            mattis et lectus. Volutpat eu suspendisse commodo ipsum habitant eu
            et porta urna. Feugiat augue tortor massa libero duis eu.
            Velit tempus integer urna lacus donec ac faucibus faucibus habitant.
            Velit duis eu mauris volutpat amet hac etiam. Arcu posuere lectus
            nunc mi commodo aliquet at laoreet. Massa mi adipiscing non
            fermentum. Tellus curabitur commodo penatibus risus nunc accumsan.
            Ac accumsan lacinia lorem porttitor euismod sed felis morbi blandit.
            Commodo morbi nibh massa neque egestas nibh. Faucibus feugiat
            molestie sit quis proin nisi id ac ultrices. Viverra cras ipsum et
            aliquam neque aliquet eget fusce elementum. Adipiscing nisl commodo
            elementum sed ultrices vel nec nibh ultricies. Quam euismod augue id
            fames a gravida nunc. Morbi nunc ornare ornare morbi. Consectetur
            arcu morbi blandit leo at. Massa nunc ullamcorper morbi imperdiet
            habitant egestas. Dui sit congue praesent dignissim feugiat vel sed
            odio. Lorem ipsum dolor sit amet consectetur. Malesuada nunc
            maecenas feugiat volutpat amet gravida nisi libero. Lorem pharetra
            vestibulum mattis et lectus. Volutpat eu suspendisse commodo ipsum
            habitant eu et porta urna. Feugiat augue tortor massa libero duis
            eu. Velit tempus integer urna lacus donec ac faucibus faucibus
            habitant. Velit duis eu mauris volutpat amet hac etiam. Arcu posuere
            lectus nunc mi commodo"
        xmlName="Format XML File"
        xmlInstruction="Format XML file Here Are the steps"
        xmlDescription="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra vestibulum
        mattis et lectus.Lorem ipsum dolor sit amet consectetur. Malesuada
        nunc maecenas feugiat volutpat amet gravida nisi libero. Lorem
        pharetra vestibulum mattis et lectus."
        step1Description="Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra
        vestibulum mattis et lectus.Lorem ipsum dolor sit amet
        consectetur."
        step2Description="  Lorem ipsum dolor sit amet consectetur. Malesuada nunc maecenas
        feugiat volutpat amet gravida nisi libero. Lorem pharetra
        vestibulum mattis et lectus.Lorem ipsum dolor sit amet
        consectetur."
        image={image}
      /> */}
    </>
  );
};

export default Json;

const useStyles = makeStyles((theme) => ({
  mainSec: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  text: {
    fontFamily: " PT Sans",
    fontSize: "32px !important",
    fontWeight: "700 !important",
    padding: "20px",
    color: theme.palette.secondary.main,
  },
}));
