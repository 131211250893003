import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const MiddleSec = ({ children }) => {
  const classes = useStyles();
  const { input, tempMessage } = useSelector((state) => state.tool);
  return (
    <div className={classes.mainMiddle}>
      <div className={classes.children}>{children}</div>
      <Typography variant="h6" sx={{ color: "green" }}>
        {tempMessage}
      </Typography>
    </div>
  );
};

export default MiddleSec;

const useStyles = makeStyles((theme) => ({
  mainMiddle: {
    padding: "20px 10px",
    backgroundColor: theme.palette.grey[400],
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    flexDirection: "column",
    gap: 40,
  },
  children: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    flexDirection: "column",
    gap: 20,
  },
}));
